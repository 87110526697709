import React from 'react'
import { Modal } from './Modal'
import {toRem, mobileAndDown} from '../utils/styleUtils';
import styled from 'styled-components'

export const DeleteModal = ({open, onClose, clickAction}) => {
    return <Modal open={open} onClose={onClose}>
        <h1>Are you sure you want to delete your tip?</h1>
        <TextButton type="button" onClick={clickAction}>Confirm</TextButton>
    </Modal>
}

const TextButton = styled.button`
  display: inline-block;
  font-family: Circular-Book;
  border: none;
  background-color: transparent;
  color: #3B9AF8;
  font-size: ${toRem(14)};
  padding: 0.2rem 0.5rem;
  border-radius: 7px;
  border: 2px solid #3B9AF8;
  cursor: pointer;
  transition: 0.5s;
  margin: 1rem;
  margin-left: 0;
  ${mobileAndDown`
    font-size: 18px;
  `}
  &:focus {
    outline:0;
  }
  &:hover {
    background-color: #3B9AF8;
    color: white;
  }
`