import { useQuery, useMutation } from "@apollo/react-hooks";
import { navigate } from "gatsby";
import gql from "graphql-tag";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import TipBox from "../components/TipBox"
import { getUserFromCookie } from "../utils/auth";
import { mobileAndDown } from '../utils/styleUtils';
import { LOCALSTORAGE_KEY_REDIRECT_URL } from "../utils/localstorage";
import { toRem } from "../utils/styleUtils";
import { DeleteModal } from "../components/DeleteModal";

const getCreatorsTips = gql`
  query listTips($creatorID: ID) {
    listTips(filter: { authorID: { eq: $creatorID } }) {
      items {
        id
        title
        subtitle
        authorID
        introduction
        headerImage
        searchTerms
      }
    }
  }
`;

const getReadyForReview = gql`
  query listDraftTips($creatorID: ID) {
    listDraftTips(
      filter: { authorID: { eq: $creatorID }, readyForReview: { eq: true } }
    ) {
      items {
        id
        title
        subtitle
        authorID
        introduction
        headerImage
        searchTerms
        readyForReview
      }
    }
  }
`;

const getCreatorsDraftTips = gql`
  query listDraftTips($creatorID: ID) {
    listDraftTips(
      filter: { authorID: { eq: $creatorID }, readyForReview: { eq: false } }
    ) {
      items {
        id
        title
        subtitle
        authorID
        introduction
        headerImage
        searchTerms
        readyForReview
      }
    }
  }
`;

const setDeleteTip = gql`
  mutation deleteDraftTip($draftTip: DeleteDraftTipInput!) {
    deleteDraftTip(input: $draftTip) {
      id
      title
      subtitle
      introduction
      headerImage
      titleLabelRGB
      subtitleLabelRGB
      titleLabelFontSize
      subtitleLabelFontSize
      code
      swiftVersion
      codeLines
      frameworks
      authorID
      searchTerms
      readyForReview
      questionPrice
      playground
      content
    lockedPositions
    }
  }
`;

const CategoryButton = (props) => {
  return (
    <TextButton className={props.title === props.currentCategory ? "active" : ""} onClick={props.onClick}>{props.title}</TextButton>
  )
}

const MyTips = (props) => {
  const [user, setUser] = useState({});
  const [creatorID, setCreatorID] = useState("");
  const [currentCategory, setCurrentCategory] = useState("All");
  const [closeButtonClicked, setCloseButtonClicked] = useState(false);
  const [currentKey, setCurrentKey] = useState(0);

  const newLocal = useQuery(getCreatorsDraftTips, {
    variables: { creatorID },
  });
  const { data: draftTipData } = newLocal;

  const { data: reviewTipData } = useQuery(getReadyForReview, {
    variables: { creatorID },
  });

  const { data: publishedTipData } = useQuery(getCreatorsTips, {
    variables: { creatorID },
  });

  const [deleteTip] = useMutation(setDeleteTip);

  useEffect(() => {
    const user = getUserFromCookie();
    if (isEmpty(user)) {
      localStorage.setItem(
        LOCALSTORAGE_KEY_REDIRECT_URL
      );
      navigate("/");
      return;
    }
    const redirectNavigateUrl = localStorage.getItem(
      LOCALSTORAGE_KEY_REDIRECT_URL
    );
    if (!!redirectNavigateUrl) {
      localStorage.removeItem(LOCALSTORAGE_KEY_REDIRECT_URL);
      navigate(redirectNavigateUrl);
      return;
    }
    setUser(user);
    setCreatorID(user.sub)
  }, []);

  if (isEmpty(user)) {
    return <div />;
  }

  const handleClick = current => {
    setCurrentCategory(current);
  }

  const handleInitialDeleteClick = (key) => {
    setCurrentKey(key);
    setCloseButtonClicked(!closeButtonClicked);
  }

  const handleDeleteClick = (key) => {
    deleteTip({ variables: { "draftTip": { "id": draftTipData.listDraftTips.items[key].id } } }).then(() => window.location.reload());
  }

  const handleCloseClick = () => {
    setCloseButtonClicked(!closeButtonClicked);
  }

  return (
    <Layout background="white" navColor="#131313" toggled={true}>
      <Container>
        <Title>My Tips Dashboard</Title>
        <ButtonContainer>
          <CategoryButton title="All" currentCategory={currentCategory} onClick={() => handleClick("All")} />
          <CategoryButton title="Published" currentCategory={currentCategory} onClick={() => handleClick("Published")} />
          <CategoryButton title="In Review" currentCategory={currentCategory} onClick={() => handleClick("In Review")} />
          <CategoryButton title="Draft" currentCategory={currentCategory} onClick={() => handleClick("Draft")} />
        </ButtonContainer>
        <TipContainer>
          {(publishedTipData !== undefined && (currentCategory === "All" || currentCategory === "Published")) ? Object.keys(publishedTipData.listTips.items)
            .map(key => (
              <TipBox data={publishedTipData.listTips.items[key]} status="Published" />
            )) : void (0)}
          {reviewTipData !== undefined && (currentCategory === "All" || currentCategory === "In Review") ? Object.keys(reviewTipData.listDraftTips.items)
            .map(key => (
              <TipBox data={reviewTipData.listDraftTips.items[key]} status="In Review" />
            )) : void (0)}
          {draftTipData !== undefined && (currentCategory === "All" || currentCategory === "Draft") ? Object.keys(draftTipData.listDraftTips.items)
            .map(key => (
              <TipBox data={draftTipData.listDraftTips.items[key]} status="Draft" onDeleteClick={() => handleInitialDeleteClick(key)} />
            )) : void (0)}
        </TipContainer>
        <DeleteModal open={closeButtonClicked} onClose={() => handleCloseClick()} clickAction={() => handleDeleteClick(currentKey)} />
      </Container>
    </Layout>
  );
};

export default MyTips;

const Container = styled.div`
    min-height: 65vh;
    margin: 5vh 5vw;
    ${mobileAndDown`
        margin: 5vh 10vw;
    `}
`

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${mobileAndDown`
    justify-content: center;
  `}
`

const Title = styled.h1`
    font-family: Circular-Bold;
    font-size: 4rem;
    letter-spacing: -0.035em;
    margin-bottom: 10px;
    color: #131313;
    ${mobileAndDown`
        font-size: 2rem;
    `}
`

const TipContainer = styled.div`
    display: flex;
    flex-flow: row wrap; 
    align-content: space-around;
`

const TextButton = styled.button`
  display: inline-block;
  font-family: Circular-Book;
  border: none;
  background-color: transparent;
  color: #3B9AF8;
  font-size: ${toRem(14)};
  padding: 0.2rem 0.5rem;
  border-radius: 7px;
  border: 2px solid #3B9AF8;
  cursor: pointer;
  transition: 0.5s;
  margin: 1rem;
  margin-left: 0;
  ${mobileAndDown`
    font-size: 18px;
    margin: 0.5rem;
  `}
  &:focus {
    outline:0;
  }
  &:hover {
    background-color: #3B9AF8;
    color: white;
  }
  &.active {
    background-color: #3B9AF8;
    color: white;
  }
`